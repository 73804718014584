import { DcBaseComponent } from '@deleteagency/dc';
import { deviceObserver } from '@deleteagency/device-observer';
import { DEVICE_MOBILE } from 'general/js/app';
import gsap from 'gsap';

const DURATION = 5;
const EASE = 'linear';

export default class ValuationTimelineComponent extends DcBaseComponent {
    static getNamespace() {
        return 'valuation-timeline';
    }

    onInit() {
        this._animate();
    }

    _animate() {
        const { duration, ease } = this.options;
        const { clip, labels, image } = this.refs;
        this.tl = gsap.timeline({
            repeat: -1,
            repeatDelay: 1,
            defaults: { ease: ease ?? EASE },
        });

        labels.forEach((label) => {
            this.tl.to(
                label,
                {
                    opacity: 1,
                    duration: 0.5,
                },
                '<0.7'
            );
        });
        this.tl.to(
            clip,
            {
                width: 910.03,
                duration: duration ?? DURATION,
            },
            0
        );
        if (deviceObserver.is('<=', DEVICE_MOBILE)) {
            const { width } = image.getBoundingClientRect();
            this.tl.to(
                this.element,
                {
                    x: `-${width - window.innerWidth / 2}px`,
                    duration: 4.5,
                },
                2.5
            );
            this.tl.to(this.element, {
                x: 0,
                duration: 0.8,
                ease: 'power2.inOut',
            });
        }
    }

    destroy() {}
}
